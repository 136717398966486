import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export enum Languages {
  Korean = 'ko',
  English = 'en',
}

const resources = {
  // ko: require('./locales/ko.json'),
  // en: require('./locales/en.json'),
};

function initI18next() {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      resources,
      lng: Languages.Korean,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        lookupLocalStorage: 'app:language',
        caches: ['localStorage'],
      },
    });
}

const AppI18next = { init: initI18next, resources, instance: i18n };
export default AppI18next;
