import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import LoadingModal from 'src/components/molecules/LoadingModal';
import AppPaths from 'src/constants/AppPaths';

const MainPage = React.lazy(() => import('src/components/pages/MainPage'));
const ExamApplyPage = React.lazy(() => import('src/components/pages/ExamApplyPage'));
const ExamApplyDonePage = React.lazy(() => import('src/components/pages/ExamApplyDonePage'));
const ExamDuplicatedApplyPage = React.lazy(() => import('src/components/pages/ExamDuplicatedApplyPage'));
const ExamPage = React.lazy(() => import('src/components/pages/ExamPage'));

const AdminRoutes = React.lazy(() => import('src/routes/AdminRoutes'));

function AppRoutes() {
  return (
    <Suspense fallback={<LoadingModal />}>
      <Switch>
        <Route exact path={AppPaths.home.path} component={MainPage} />

        {/* Editor */}
        <Route exact path={AppPaths.examDuplicatedApply.path} component={ExamDuplicatedApplyPage} />
        <Route exact path={AppPaths.examApplyDone.path} component={ExamApplyDonePage} />
        <Route exact path={AppPaths.examApply.path} component={ExamApplyPage} />
        <Route exact path={AppPaths.exam.path} component={ExamPage} />

        {/* ADMIN */}
        <Route path={AppPaths.admin.path} component={AdminRoutes} />

        {/* Fallback */}
        <Route>Page Not Found</Route>
      </Switch>
    </Suspense>
  );
}

export default AppRoutes;
