import { createGlobalStyle } from 'styled-components';

export const RootStyle = createGlobalStyle`
  html, body, #root {
    margin: 0;
    padding: 0;

    width: 100%;
    height: 100%;

    font-family: 'Noto Sans KR', AppleSDGothicNeo;
  }

  [lang='ja'] {
    font-family: 'Noto Sans JP', 'Noto Sans KR';
  }

  [lang='zhCN'], [lang='zhTW'] {
    font-family: 'Noto Sans SC', 'Noto Sans TC', 'Noto Sans KR';
  }

`;
