import { useMount } from '@voithru/front-core';
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components/macro';
import { ModalStyle } from './styled';

type Props = React.ComponentPropsWithoutRef<'div'>;

let id = 0;
const BaseModal = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const element = React.useMemo(() => {
    id += 1;

    const e = document.createElement('div');
    e.id = `modal-${id}`;
    e.className = 'modal';
    document.body.append(e);

    return e;
  }, []);

  useMount(() => {
    return () => element.remove();
  });

  return ReactDOM.createPortal(
    <>
      <ModalStyle />
      <div ref={ref} {...props} />
    </>,
    element
  );
});

export const Modal = styled(BaseModal)``;

export const BackgroundModal = styled(BaseModal)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  background: rgba(0, 0, 0, 0.32);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;
