import { createGlobalStyle } from 'styled-components';

export const ModalStyle = createGlobalStyle`
  body > .modal {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
  }
`;
