import styled, { keyframes } from 'styled-components';

const LoadingAnime = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingWrapper = styled.div`
  --loading-black: #2f2e38;
  --loading-white: white;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 80px;
  height: 80px;

  animation: ${LoadingAnime} 2.4s linear infinite;

  & > div {
    position: absolute;

    border: 6px solid transparent;
    border-top-color: var(--loading-black);
    border-radius: 50%;
    width: 48px;
    height: 48px;
    box-sizing: border-box;

    animation: ${LoadingAnime} 1s ease-in-out infinite;

    &:nth-child(1) {
      animation-delay: -0.3s;
    }

    &:nth-child(2) {
      animation-delay: -0.4s;
    }

    &:nth-child(3) {
      animation-delay: -0.5s;
    }
  }

  &.white > div {
    border-top-color: var(--loading-white);
  }
`;
