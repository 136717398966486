import { Path } from 'src/types/Path';

/**
 * USER
 */
const home = Object.freeze<Path>({
  name: 'Home',
  path: '/',
});

const exam = Object.freeze<Path>({
  path: '/exam/:id',
});

const examApply = Object.freeze<Path>({
  path: '/exam/:id/apply',
});

const examApplyDone = Object.freeze<Path>({
  path: '/exam/:id/apply/done',
});

const examDuplicatedApply = Object.freeze<Path>({
  path: '/exam/:id/apply/duplicated',
});

/**
 * ADMIN
 */
const admin = Object.freeze<Path>({
  name: 'Admin',
  path: '/admin',
});

const adminExamList = Object.freeze<Path>({
  path: '/admin/exam',
});

const adminExamRegister = Object.freeze<Path>({
  path: '/admin/exam/register',
});

const adminExamEdit = Object.freeze<Path>({
  path: '/admin/exam/:id/edit',
});

const adminExamResults = Object.freeze<Path>({
  path: '/admin/exam/:id/results',
});

const adminExamEvaluate = Object.freeze<Path>({
  path: '/admin/exam/:id/results/:testsubmissionid',
});

const adminExamSources = Object.freeze<Path>({
  path: '/admin/sources',
});

const adminExamSourceRegister = Object.freeze<Path>({
  path: '/admin/sources/register',
});

const adminExamSourceEdit = Object.freeze<Path>({
  path: '/admin/sources/:id',
});

const adminExamSourceEditEditor = Object.freeze<Path>({
  path: '/admin/sources/:id/editor',
});

const AppPaths = Object.freeze({
  // USER
  home,
  exam,
  examApply,
  examApplyDone,
  examDuplicatedApply,

  // ADMIN
  admin,
  adminExamList,
  adminExamRegister,
  adminExamEdit,
  adminExamResults,
  adminExamEvaluate,
  adminExamSources,
  adminExamSourceRegister,
  adminExamSourceEdit,
  adminExamSourceEditEditor,
});

export default AppPaths;
