import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import AppI18next from 'src/constants/i18next';
import ErrorBoundary from './components/atoms/ErrorBoundary';
import { ApplicationContextProvider } from './components/context/ApplicationContext';
import reportWebVitals from './reportWebVitals';
import AppRoutes from './routes/AppRoutes';
import { RootStyle } from './theme/root';

import 'core-js/features/object/from-entries';

AppI18next.init();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <RootStyle />
      <ApplicationContextProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ApplicationContextProvider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
