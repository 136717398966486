import { cn } from '@voithru/front-core';
import styled from 'styled-components';
import { LoadingWrapper } from './styled';

interface Props {
  className?: string;
  style?: React.CSSProperties;

  white?: boolean;
}

const Loading: React.FunctionComponent<Props> = (props) => {
  const { className, style, white } = props;
  return (
    <LoadingWrapper className={cn(white && 'white', className)} style={style}>
      <div />
      <div />
      <div />
    </LoadingWrapper>
  );
};

export default styled(Loading)``;
