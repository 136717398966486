import Loading from 'src/components/atoms/Loading';
import { BackgroundModal } from 'src/components/atoms/Modal';
import styled from 'styled-components';

const FullscreenModal = styled(BackgroundModal)`
  background: rgba(255, 255, 255, 0.75);
`;

const LoadingModal: React.FunctionComponent = (props) => {
  return (
    <FullscreenModal>
      <Loading />
    </FullscreenModal>
  );
};

export default LoadingModal;
